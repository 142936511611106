import workspace from '@/components/parts/workspace/workspace.state'
const SCRATCH_TYPE = {
  LINE: 'Line',
  CIRCLE: 'Circle',
  SPRAY: 'Spray',
  BRUSH: 'Brush'
}

export default {
  revealType: 'image',
  scratchSource: 'default',
  html: '',
  overlays: {
    Grey: {
      label: 't_scratch_gray_',
      value: 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/scratch_grey-texture.png'
    },
    Black: {
      label: 't_scratch_black_',
      value: 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/scratch_black-texture.png'
    },
    White: {
      label: 't_scratch_white_',
      value: 'https://s3-eu-west-1.amazonaws.com/www.snblymm.com/1MSending/Images/scratch_white-texture.png'
    }
  },
  brushes: {
    options: [
      {
        id: 0,
        label: 't_brush_line_',
        value: SCRATCH_TYPE.LINE
      },
      {
        id: 1,
        label: 't_brush_spray_',
        value: SCRATCH_TYPE.SPRAY
      },
      {
        id: 2,
        label: 't_brush_circle_',
        value: SCRATCH_TYPE.CIRCLE
      }
      // TODO: Need to implement custom brush for this option
      // you can take a look: https://masth0.github.io/ScratchCard/
      // {
      //   id: 3,
      //   label: 't_brush_brush_',
      //   value: SCRATCH_TYPE.BRUSH
      // }
    ]
  },
  widthManual: true,
  imageWidth: null,
  tempWidth: parseInt(workspace.template.children[0].attributes.width),
  maxWidth: parseInt(workspace.template.children[0].attributes.width)
}
