// Component states
import states from './settings-scratch-modifier.state.js'
import workspace from '@/components/parts/workspace/workspace.state'

// Conf
// import html from './scratch-html-exemple.const.js'

// Services
import { landingPageMode, expandLeftPanel } from '@/services/states/states.service'

// Components
import PrismEditor from 'vue-prism-editor'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import MdiMenuRight from 'vue-material-design-icons/MenuRight.vue'
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'

// Library
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-scratch'

// Properties
const props = {
  mjml: Object,
  show: Boolean
}

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspace
  }
}
// Vue@data

// Components
const components = {
  PrismEditor,
  MdiMenuRight,
  ImagePathModifier,
  AlignHorizontalContentModifier
}

// Methods
const methods = {
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _setImageProperties (src) {
    const img = new Image()
    img.onload = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'maxWidth', img.width > bodySize ? bodySize : img.width)
      this.$set(this.states, 'imageWidth', img.width)
      if (this.states.maxWidth < this.states.tempWidth) {
        this.$set(this.states, 'tempWidth', this.states.maxWidth)
      }
    }
    img.onerror = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'imageWidth', null)
      this.$set(this.states, 'maxWidth', bodySize)
      this.$set(this.states, 'tempWidth', this.states.maxWidth)
    }
    img.src = src
  },

  // Func@toggleDevMode
  /**
   * Activate dev mode
   */
  toggleDevMode () {
    expandLeftPanel()
    this.$set(this.states, 'devMode', !this.states.devMode)
  }
  // Func@toggleDevMode
}

// Computed Methods
const computed = {

  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },

  manualWidth: {
    // getter
    get () {
      return this.states.widthManual
    },
    // setter
    set (newValue) {
      this.states.widthManual = newValue
      this.mjml.attributes.width = newValue ? `${parseInt(this.states.tempWidth)}px` : ''
    }
  },

  width: {
    // getter
    get () {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      if (this.mjml.attributes.width !== '') {
        this.states.tempWidth = this.mjml.attributes.width
      }

      const width = this.states.widthManual
        ? this.mjml.attributes.width || bodySize
        : this.states.tempWidth

      return parseInt(width, 10)
    },
    // setter
    set (newValue) {
      if (!this.manualWidth) return
      this.mjml.attributes.width = `${newValue}px`
    }
  }
}

const watch = {
  'mjml.attributes.src': function (newSrc) {
    this._setImageProperties(newSrc)
  },
  'mjml.attributes.srcOverlay': function (newSrc) {
    this._setImageProperties(newSrc)
    this.mjml.attributes.srcOverlay = newSrc
  },
  'mjml.attributes.predefinedOverlay': {
    handler (newId) {
      if (newId) {
        this.mjml.attributes.predefinedOverlay = newId
        this.mjml.attributes.srcOverlay = ''
      }
    }
  },
  'states.scratchSource': {
    handler (newStr) {
      if (newStr === 'upload') {
        this.mjml.attributes.predefinedOverlay = ''
        this.mjml.attributes.srcOverlay = ''
      } else {
        this.mjml.attributes.predefinedOverlay = 'AnimatedGrey'
        this.mjml.attributes.srcOverlay = ''
      }
    }
  }
}

/**
 * Init widthAuto state
 */
function initState () {
  if (!this.mjml.attributes.width) this.states.widthManual = false
  this._setImageProperties(this.mjml.attributes.src)

  if (!this.mjml.attributes.predefinedOverlay) {
    this.states.scratchSource = 'upload'
  }

  if (!this.mjml.attributes.scratchType) {
    this.mjml.attributes.scratchType = 'Line'
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  created: initState
}
